import type { DynamicPageProps } from '@/core/features/a-dynamic-page/dynamic-page-types';

import dynamic from 'next/dynamic';

import { ErrorPage } from '@/core/features';

const DesktopS0Layout = dynamic(
    () => import(/* webpackChunkName: "DesktopS0Layout" */ '@/features/page-layouts/desktop/desktop-s0-layout'),
);
const DesktopS1Layout = dynamic(
    () => import(/* webpackChunkName: "DesktopS1Layout" */ '@/features/page-layouts/desktop/desktop-s1-layout'),
);
const DesktopS2Layout = dynamic(
    () => import(/* webpackChunkName: "DesktopS2Layout" */ '@/features/page-layouts/desktop/desktop-s2-layout'),
);

const DesktopAsideRightLayout = dynamic(
    () =>
        import(
            /* webpackChunkName: "DesktopAsideRightLayout" */ '@/features/page-layouts/desktop/desktop-aside-right-v1-layout'
        ),
);

const DesktopThirdViewLayout = dynamic(
    () =>
        import(
            /* webpackChunkName: "DesktopThirdViewLayout" */ '@/features/page-layouts/desktop/desktop-third-view-layout'
        ),
);
const DesktopCheckoutLayout = dynamic(
    () =>
        import(
            /* webpackChunkName: "DesktopCheckoutLayout" */ '@/features/page-layouts/desktop/desktop-checkout-layout'
        ),
);
const DesktopMapSearchLayout = dynamic(
    () =>
        import(
            /* webpackChunkName: "DesktopMapSearchLayout" */ '@/features/page-layouts/desktop/desktop-map-search-layout'
        ),
);

const DesktopPpsetTeaserLayout = dynamic(
    () =>
        import(
            /* webpackChunkName: "DesktopPPSETTeaserLayout" */ '@/features/page-layouts/desktop/desktop-ppset-teaser-layout'
        ),
);

export default function DesktopLayoutSwitch(props: DynamicPageProps) {
    const layoutId = props.meta?.layoutId ?? null;

    switch (layoutId) {
        case 'Desktop_S0_V1':
            return <DesktopS0Layout {...props} />;
        case 'Desktop_S1_V1':
            return <DesktopS1Layout {...props} />;
        case 'Desktop_Map_Search_V1':
            return <DesktopMapSearchLayout {...props} />;
        case 'Desktop_S2_V1':
        case 'Desktop_S3_V1':
            return <DesktopS2Layout {...props} />;
        case 'Desktop_Checkout_V1':
            return <DesktopCheckoutLayout {...props} />;
        case 'Desktop_3rd_V1':
            return <DesktopThirdViewLayout {...props} />;
        case 'Desktop_PPSET_Teaser_V1':
            return <DesktopPpsetTeaserLayout {...props} />;
        case 'Desktop_Aside_Right_V1':
            return <DesktopAsideRightLayout {...props} />;
        case 'App_Error_Page': {
            return (
                <ErrorPage
                    detail={'Layout Desktop'}
                    isPageLoading={props.isPageLoading}
                    statusCode={500}
                />
            );
        }
        default: {
            return <DesktopS0Layout {...props} />;
        }
    }
}
